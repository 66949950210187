<template>
  <div class="ng-content-wrap">
    <div class="ng-block-head ng-block-head-lg">
      <div class="ng-block-head-sub d-print-none">
        <router-link class="back-to" to="/">
          <em class="icon ni ni-chevron-left-c mr-1"></em>
          <span>Dashboard</span>
        </router-link>
      </div>
      <div class="ng-block-between-md g-4">
        <div class="ng-block-head-content">
          <h2 class="ng-block-title fw-normal">
            {{ $route.meta.title }}
          </h2>
          <div class="ng-block-des d-print-none">
            <p>
              Week <strong>{{ fD(start, "ww") }}</strong> (<b>{{
                fD(start, "E, do MMM")
              }}</b>
              - <b>{{ fD(end, "E, do MMM") }}</b
              >)
            </p>
          </div>
        </div>
        
        <div class="ng-block-head-content">
          <ul class="ng-block-tools gx-3 d-print-none">
            <li v-if="farms.length">
              <div class="input-group">
                <div class="input-group-prepend">
                  <span
                    class="input-group-text bg-primary text-white font-weight-bold"
                  >
                    Farm
                  </span>
                </div>
                <Multiselect
                  :options="farms"
                  v-model="farm_id"
                  trackBy="name"
                  label="name"
                  valueProp="id"
                  :searchable="true"
                  placeholder="Type to search..."
                  @change="fetchVarieties"
                />
              </div>
            </li>
            <li v-if="farm_id">
              <a
                href="#pickDates"
                data-toggle="modal"
                class="btn btn-white btn-dim btn-outline-primary"
              >
                <em class="icon ni ni-calendar"></em>
                <span>
                  <span class="d-none d-sm-inline-block"> Start </span>
                  Date
                </span>
              </a>
              <div class="modal fade" tabindex="-1" id="pickDates">
                <div class="modal-dialog" role="document">
                  <div class="modal-content justify-content-center">
                    <button
                      id="hideNewForm"
                      class="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <em class="icon ni ni-cross text-white"></em>
                    </button>

                    <div class="modal-header bg-primary">
                      <h5 class="modal-title text-white">Select Start Date</h5>
                    </div>

                    <div class="modal-body row justify-content-center">
                      <div
                        class="col-md-7 justify-content-center text-center mb-1"
                      >
                        <v-date-picker v-model="start" />
                      </div>
                      <div class="col-md-5 text-center">
                        <h5>Starting on</h5>
                        <p>
                          {{ fD(start) }}
                        </p>

                        <h5>Ending on</h5>
                        <p>
                          {{ fD(end) }}
                        </p>

                        <button
                          class="btn btn-outline-primary float-right"
                          data-dismiss="modal"
                        >
                          <span> Use Selected Dates </span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="ng-block">
      <div class="card card-bordered loading" v-if="processing">
        <div class="justify-content-center text-center">
          <div class="spinner-grow text-primary m-3" role="status">
            <span class="sr-only">Processing Data...</span>
          </div>
          <h6 class="mb-4">Processing Data...</h6>
        </div>
      </div>

      <div
        class="card"
        :class="{ 'card-bordered': farm_id && varieties.length }"
        v-else
      >
        <div class="card-body" v-if="farm_id">
          <ul class="nav nav-tabs">
            <li
              class="nav-item"
              v-for="(d, i) in getDates(start, end)"
              :key="i"
            >
              <a
                class="nav-link"
                :class="{ active: i == day }"
                data-toggle="tab"
                :href="`#day${i}`"
                @click="setDay(i, d)"
              >
                {{ fD(new Date(d), "E, do MMM") }}
              </a>
            </li>
          </ul>
          <div class="tab-content">
            <div
              v-for="(d, i) in getDates(start, end)"
              :key="i"
              class="tab-pane"
              :id="`day${i}`"
              :class="{ active: i == day }"
            >
              <div class="ng-block ng-block-lg">
                <div class="ng-block-head px-4">
                  <div class="ng-block-head-content">
                    <h5 class="ng-block-title d-none">
                      {{ fD(new Date(d)) }}
                    </h5>

                    <div class="ng-block-des d-print-none">
                      <p>
                        <b class="float-left">
                          {{ farmName }};
                          {{ fD(new Date(d)) }}
                        </b>
                      </p>
                      <button
                        class="btn btn-primary float-right btn-icon btn-round"
                        type="submit"
                      >
                        <em class="icon ni ni-save"> </em>
                      </button>
                    </div>
                  </div>
                </div>
                <form
                  class="card w-100"
                  method="POST"
                  @submit.prevent="savePicks"
                >
                  <div class="card-body row">
                    <div
                      class="col-md-3"
                      style="overflow-y: scroll; max-height: 40vh"
                    >
                      <ul
                        class="nav nav-tabs flex-column"
                        id="myTab"
                        role="tablist"
                      >
                        <li
                          class="nav-item"
                          v-for="(p, i) in varieties"
                          :key="i"
                        >
                          <a
                            class="nav-link"
                            :class="{
                              active: p.id == prod,
                            }"
                            :id="`tab${p.id}`"
                            data-toggle="tab"
                            :href="`#pack${p.id}`"
                            role="tab"
                            :aria-controls="`pack${p.id}`"
                            :aria-selected="p.id == prod"
                            @click="setProd(p.id)"
                          >
                            {{ p.name }}
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div class="col-md-9 tab-content" id="myTabContent">
                      <div
                        v-for="(p, i) in varieties"
                        :key="i"
                        class="tab-pane fade show"
                        :class="{
                          active: p.id == prod,
                        }"
                        :id="`pack${p.id}`"
                        role="tabpanel"
                        :aria-labelledby="`tab${p.id}`"
                      >
                        <div class="card card-bordered" v-if="form[d][p.id]">
                          <div class="card-header">
                            <h5 class="card-title">
                              {{ p.name }}
                              <span class="float-right">
                                Projected:
                                {{ form[d][p.id]["projected"] }}
                              </span>
                            </h5>
                          </div>
                          <div class="card-body row">
                            <div class="form-group col-md-4">
                              <label> Amended Projection </label>
                              <input
                                class="form-control"
                                type="number"
                                v-model="form[d][p.id]['ammended']"
                              />
                            </div>
                            <div class="form-group col-md-4">
                              <label> WhatsApp Declared </label>

                              <div class="input-group">
                                <input
                                  class="form-control"
                                  type="number"
                                  v-model="form[d][p.id]['declared']"
                                />
                                <div class="input-group-append">
                                  <span
                                    class="input-group-text"
                                    :class="{
                                      'bg-primary text-white':
                                        getPercentage(
                                          form[d][p.id]['declared'],
                                          form[d][p.id]['ammended']
                                        ) > 100,
                                      'bg-danger text-white':
                                        getPercentage(
                                          form[d][p.id]['declared'],
                                          form[d][p.id]['ammended']
                                        ) < 100,
                                    }"
                                  >
                                    {{
                                      getPercentage(
                                        form[d][p.id]["declared"],
                                        form[d][p.id]["ammended"]
                                      )
                                    }}%</span
                                  >
                                </div>
                              </div>
                            </div>

                            <div class="form-group col-md-4">
                              <label> Delivery Note Weight </label>
                              <div class="input-group">
                                <input
                                  class="form-control"
                                  type="number"
                                  v-model="form[d][p.id]['dnote']"
                                />
                                <div class="input-group-append">
                                  <span
                                    class="input-group-text"
                                    :class="{
                                      'bg-primary text-white':
                                        getPercentage(
                                          form[d][p.id]['dnote'],
                                          form[d][p.id]['declared']
                                        ) > 100,
                                      'bg-danger text-white':
                                        getPercentage(
                                          form[d][p.id]['dnote'],
                                          form[d][p.id]['declared']
                                        ) < 100,
                                    }"
                                  >
                                    {{
                                      getPercentage(
                                        form[d][p.id]["dnote"],
                                        form[d][p.id]["declared"]
                                      )
                                    }}%
                                  </span>
                                </div>
                              </div>
                            </div>

                            <div class="form-group col-md-4">
                              <label> Intake Weight </label>
                              <div class="input-group">
                                <input
                                  class="form-control"
                                  type="number"
                                  v-model="form[d][p.id]['intake']"
                                />
                                <div class="input-group-append">
                                  <span
                                    class="input-group-text"
                                    :class="{
                                      'bg-primary text-white':
                                        getPercentage(
                                          form[d][p.id]['intake'],
                                          form[d][p.id]['dnote']
                                        ) > 100,

                                      'bg-danger text-white':
                                        getPercentage(
                                          form[d][p.id]['intake'],
                                          form[d][p.id]['dnote']
                                        ) < 100,
                                    }"
                                  >
                                    {{
                                      getPercentage(
                                        form[d][p.id]["intake"],
                                        form[d][p.id]["dnote"]
                                      )
                                    }}%
                                  </span>
                                </div>
                              </div>
                            </div>

                            <div class="form-group col-md-4">
                              <label> Farm Packout </label>
                              <input
                                class="form-control"
                                v-model="form[d][p.id]['fpackout']"
                              />
                            </div>

                            <div class="form-group col-md-4">
                              <label> Packhouse Packout </label>
                              <input
                                class="form-control"
                                v-model="form[d][p.id]['tpackout']"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="card-body justify-content-between">
                    <router-link
                      :to="`/picks/day/${d}?farm_id=${farm_id}`"
                      class="btn btn-outline-light btn-sm"
                    >
                      <em class="icon ni ni-save"> </em>
                      <span>
                        View Summary for
                        {{ fD(new Date(d), "EEEE") }}
                      </span>
                    </router-link>

                    <button class="btn btn-primary float-right" type="submit">
                      <em class="icon ni ni-save"> </em>

                      <span class="pl-1 d-none d-sm-block">
                        Save for
                        {{ fD(new Date(d), "do MMM") }}
                      </span>
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        <div class="alert alert-warning text-center" v-else>
          <h5>
            <em class="icon ni ni-alert text-warning"></em>
          </h5>

          Please select a farm to set picks for.
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import http from "../../../../common/services/http";
import {
  defineComponent,
  computed,
  ref,
  reactive,
  onMounted,
  watch,
} from "vue";
import moment from "moment";
import router from "../router";
import { format } from "date-fns";

export default defineComponent({
  setup(props) {
    const form = ref({});

    const farms = ref([]);
    const farm_id = ref(0);

    const farmName = computed(() => {
      const n = farms.value?.find((f: any) => f.id == farm_id.value);

      return n?.["name"];
    });

    const varieties = ref([]);

    const search = ref("");

    const processing = ref(false);
    const created = ref(false);
    const updated = ref(false);
    const day = ref(0);
    const date = ref(new Date());

    const today = computed(() => new Date());

    const prod = ref(1);

    function wednesday() {
      const date = new Date();
      const last = new Date(new Date().getTime() - 6 * 24 * 60 * 60 * 1000);

      return date.getDay() < 3
        ? new Date(last.setDate(last.getDate() - (last.getDay() - 1) + 2))
        : new Date(date.setDate(date.getDate() - (date.getDay() - 1) + 2));
    }
    const start = ref(wednesday());
    const end = computed(
      () => new Date(new Date(start.value).getTime() + 6 * 24 * 60 * 60 * 1000)
    );

    const masks = ref({
      input: "YYYY-MM-DD",
    });

    function getDates(s: any) {
      const e = new Date(new Date(s).getTime() + 6 * 24 * 60 * 60 * 1000);

      const dates = [];
      let currentDate = moment(s);
      const eDate = moment(e);
      while (currentDate <= eDate) {
        dates.push(moment(currentDate).format("YYYY-MM-DD"));
        currentDate = moment(currentDate).add(1, "days");
      }

      return dates;
    }

    function fD(d: any, f = "PPPP") {
      return format(d, f);
    }

    function fetchFarms() {
      processing.value = true;
      http.get(`/api/farms?fetch=all`).then((res) => {
        farms.value = res.data;
        processing.value = false;
      });
    }

    async function fetchPicks(f: any) {
      const res = await http.get(
        `/api/picks?farm_id=${f}&start=${fD(
          start.value,
          "yyyy-MM-dd"
        )}&end=${fD(end.value, "yyyy-MM-dd")}`
      );

      return res.data.data;
    }

    function fetchVarieties(id: any) {
      processing.value = true;

      fetchPicks(id).then((ps: any) => {
        http.get(`/api/varieties?fetch=all`).then((res) => {
          const a = res.data;
          varieties.value = a;

          const dates = getDates(start.value);

          form.value = dates.reduce((o, d) => {
            const p: any = ps.find((or: any) => or.date == d);

            return {
              ...o,
              [d]: a.reduce(
                (r: any, v: any) => ({
                  ...r,
                  [v.id]: {
                    projected:
                      ((p?.quantities as any)[v.id] as any)?.projected || 0,
                    ammended:
                      ((p?.quantities as any)[v.id] as any)?.ammended || 0,
                    declared:
                      ((p?.quantities as any)[v.id] as any)?.declared || 0,
                    intake: ((p?.quantities as any)[v.id] as any)?.intake || 0,
                    dnote: ((p?.quantities as any)[v.id] as any)?.dnote || 0,
                    fpackout:
                      ((p?.quantities as any)[v.id] as any)?.fpackout || 0,
                    tpackout:
                      ((p?.quantities as any)[v.id] as any)?.tpackout || 0,
                  },
                }),
                {}
              ),
            };
          }, {});

          processing.value = false;
        });
      });
    }

    function searchVarieties() {
      fetchPicks(farm_id.value).then((ps: any) => {
        http.get(`/api/varieties?s=${search.value}&fetch=all`).then((res) => {
          const a = res.data.data;
          varieties.value = a;

          const dates = getDates(start.value);

          form.value = dates.reduce((o, d) => {
            const p: any = ps.find((or: any) => or.date == d);

            return {
              ...o,
              [d]: a.reduce(
                (r: any, v: any) => ({
                  ...r,
                  [v.id]: {
                    projected:
                      ((p?.quantities as any)[v.id] as any)?.projected || 0,
                    ammended:
                      ((p?.quantities as any)[v.id] as any)?.ammended || 0,
                    declared:
                      ((p?.quantities as any)[v.id] as any)?.declared || 0,
                    intake: ((p?.quantities as any)[v.id] as any)?.intake || 0,
                    dnote: ((p?.quantities as any)[v.id] as any)?.dnote || 0,
                    fpackout:
                      ((p?.quantities as any)[v.id] as any)?.fpackout || 0,
                    tpackout:
                      ((p?.quantities as any)[v.id] as any)?.tpackout || 0,
                  },
                }),
                {}
              ),
            };
          }, {});
        });
      });
    }

    function savePicks() {
      http
        .post("/api/picks", {
          farm_id: farm_id.value,
          picks: form.value,
          start: start.value,
          end: end.value,
        })
        .then((res) => {
          if (res.data.success) {
            created.value = true;
          }
        })
        .finally(() => {
          fetchVarieties(farm_id.value);
        });
    }

    function setDay(i = 0, d: any) {
      day.value = i;
      date.value = d;
    }

    function setProd(n: 0) {
      prod.value = n;
    }

    function getPercentage(a: any, b: any) {
      return b === 0 ? 0 : Math.round((a / b) * 100);
    }

    onMounted(() => {
      fetchFarms();

      if (router.currentRoute.value.query?.farm_id) {
        const f = router.currentRoute.value.query?.farm_id;

        farm_id.value = Number(f);
        fetchVarieties(f);
      } else {
        farm_id.value = 1;
        fetchVarieties(1);
      }
    });

    watch(
      () => end.value,
      (v: any) => {
        fetchVarieties(farm_id.value);
      }
    );

    return {
      fetchVarieties,
      processing,
      search,
      searchVarieties,
      varieties,
      savePicks,
      created,
      updated,
      fD,
      today,
      start,
      end,
      masks,
      getDates,
      setDay,
      day,
      date,
      form,
      farms,
      farmName,
      farm_id,
      prod,
      setProd,
      getPercentage,
    };
  },
});
</script>
